import React, { FC } from 'react';

import Dropzone, { DropzoneState } from 'react-dropzone';
import { Field } from 'react-final-form';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { MessageAttachmentView } from '@travauxlib/shared/src/types/api/pro/MessageView';
import { formatFileSize } from '@travauxlib/shared/src/utils/format';

import { useConversation } from 'features/Deals/api/useConversation';
import { MAX_FILE_SIZE } from 'utils/constants';

type ConversationDropZoneProps = {
  conversationUuid: string;
  trigger: (arg: DropzoneState) => React.ReactElement;
};

export const ConversationDropZone: FC<ConversationDropZoneProps> = ({
  conversationUuid,
  trigger,
}) => {
  const { uploadAttachment } = useConversation(conversationUuid);
  return (
    <Field<MessageAttachmentView[]>
      name="attachments"
      render={({ input: { onChange, value = [] } }) => (
        <Dropzone
          multiple
          onDrop={async (acceptedFiles, rejectedFiles) => {
            rejectedFiles.forEach(({ file }) =>
              toast.error(`Impossible de joindre le fichier "${file.name}" \
car il est trop volumineux (${formatFileSize(file.size)}). \
Taille maximum autorisée : ${formatFileSize(MAX_FILE_SIZE)}.`),
            );
            const newAttachments = await Promise.all(
              acceptedFiles.map(attachment => uploadAttachment({ attachment, conversationUuid })),
            );
            onChange([...value, ...newAttachments]);
          }}
          maxSize={MAX_FILE_SIZE}
        >
          {trigger}
        </Dropzone>
      )}
    />
  );
};
