import { useQuery, useQueryClient } from '@tanstack/react-query';

import { ProCompanyAdminView } from '@travauxlib/shared/src/types/api/admin/ProCompanyAdminView';
import { request } from '@travauxlib/shared/src/utils/request';

import { TRANSFER_DEVIS_KEY } from './useProCompanyDevisList';
import { TRANSFER_PRO_CLIENTS_KEY } from './useProCompanyProClients';

export const useProCompanies = (): ProCompanyAdminView[] => {
  const queryClient = useQueryClient();
  const { data: proCompanies = [] } = useQuery<ProCompanyAdminView[]>({
    queryKey: ['external-pro-companies'],
    queryFn: async () => {
      const requestURL = `${APP_CONFIG.apiURL}/admin/pro-companies?withClubPro=true&status=ACTIVEE&status=LABELLISEE`;
      const { data } = await request(requestURL);

      queryClient.invalidateQueries({ queryKey: [TRANSFER_DEVIS_KEY] });
      queryClient.invalidateQueries({ queryKey: [TRANSFER_PRO_CLIENTS_KEY] });
      return data;
    },
  });

  return proCompanies;
};
