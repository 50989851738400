import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProUserProfileView } from '@travauxlib/shared/src/types/api/common/ProUserProfileView';
import { ConversationParticipantView } from '@travauxlib/shared/src/types/api/pro/ConversationParticipantView';
import { DealView } from '@travauxlib/shared/src/types/api/pro/DealView';
import { request, isConflict } from '@travauxlib/shared/src/utils/request';

import { useProfile } from 'api/useProfile';

import { getParticipantStatus } from '../components/MessagesList/utils/isReadOnlyParticipant';

type CreateOrUpdatePayload = {
  conversationUuid: string;
  name: string;
  email: string;
  uuid?: string;
};

export const CONVERSATION_PARTICIPANTS_KEY = 'CONVERSATION_PARTICIPANTS';

const participantsListFormater = (
  deal: DealView,
  profile: ProUserProfileView,
  participants: ConversationParticipantView[] = [],
): Omit<ConversationParticipantView, 'uuid'>[] =>
  participants
    .map(participant => {
      switch (getParticipantStatus(participant, deal, profile)) {
        case 'readonly':
          return { uuid: '', name: participant.name, email: participant.email, hidden: false };
        default:
          return participant;
      }
    })
    .filter(participant => !participant.hidden);

export const useParticipants = (
  deal: DealView,
): { participants: Omit<ConversationParticipantView, 'uuid'>[]; isLoading: boolean } => {
  const { data, isLoading } = useQuery<{ data: ConversationParticipantView[] }>({
    queryKey: [CONVERSATION_PARTICIPANTS_KEY, deal.conversationUuid],
    queryFn: () =>
      request(`${APP_CONFIG.proApiURL}/conversations/${deal.conversationUuid}/participants`),
  });
  const { profile } = useProfile();
  return { participants: participantsListFormater(deal, profile, data?.data), isLoading };
};

const handleCreateOrUpdate = async ({
  conversationUuid,
  email,
  name,
  uuid,
}: CreateOrUpdatePayload): Promise<string> => {
  if (uuid) {
    try {
      await request(
        `${APP_CONFIG.proApiURL}/conversations/${conversationUuid}/participants/${uuid}`,
        {
          method: 'PUT',
          body: { name, email },
        },
      );
      toast.success('Le participant a bien été modifié');
    } catch {
      toast.error('Une erreur est survenue');
    }
  } else {
    try {
      await request(`${APP_CONFIG.proApiURL}/conversations/${conversationUuid}/participants`, {
        method: 'POST',
        body: { name, email },
      });
      toast.success('Le participant a bien été créé');
    } catch (err) {
      if (err instanceof Error && isConflict(err)) {
        toast.error('Un membre de la conversation utilise déjà cette adresse email');
      } else {
        toast.error('Une erreur est survenue');
      }
    }
  }

  return conversationUuid;
};

export const useCreateOrUpdateParticipant = (): {
  isLoading: boolean;
  createOrUpdate: typeof handleCreateOrUpdate;
} => {
  const queryClient = useQueryClient();

  const { mutateAsync: createOrUpdate, isPending } = useMutation({
    mutationFn: handleCreateOrUpdate,
    onSuccess: conversationUuid => {
      queryClient.invalidateQueries({
        queryKey: [CONVERSATION_PARTICIPANTS_KEY, conversationUuid],
      });
    },
  });
  return {
    isLoading: isPending,
    createOrUpdate,
  };
};

const handleRemove = async ({
  conversationUuid,
  uuid,
}: {
  conversationUuid: string;
  uuid: string;
}): Promise<string> => {
  await request(`${APP_CONFIG.proApiURL}/conversations/${conversationUuid}/participants/${uuid}`, {
    method: 'DELETE',
  });
  toast.success('Le participant a bien été supprimé');
  return conversationUuid;
};

export const useRemoveParticipant = (): { remove: typeof handleRemove; isLoading: boolean } => {
  const queryClient = useQueryClient();
  const { mutateAsync: remove, isPending } = useMutation({
    mutationFn: handleRemove,
    onSuccess: conversationUuid => {
      queryClient.invalidateQueries({
        queryKey: [CONVERSATION_PARTICIPANTS_KEY, conversationUuid],
      });
    },
  });
  return {
    isLoading: isPending,
    remove,
  };
};
