import { S3FileView } from '@travauxlib/shared/src/types/api/common/S3FileView';
import { DealNoteView } from '@travauxlib/shared/src/types/api/pro/DealView';
import { MessageAttachmentView } from '@travauxlib/shared/src/types/api/pro/MessageView';

import { MessageFrontView } from '../types';

function s3ToAttachment(s3: S3FileView[]): Omit<MessageAttachmentView, 'createdAt'>[] {
  return s3.map(file => ({
    uuid: file.uuid,
    url: file.downloadUrl,
    filekey: file.key,
    filename: file.name,
  }));
}

export function notesToMessages(notes: DealNoteView[] = []): MessageFrontView[] {
  return notes.map(note => ({
    content: note.content,
    createdAt: note.createdAt,
    attachments: s3ToAttachment(note.files),
    uuid: note.createdAt,
    type: 'participant-message',
  }));
}
