import React from 'react';

import { Form } from 'react-final-form';
import { useNavigate } from 'react-router';

import Invoice from '@travauxlib/shared/src/components/DesignSystem/assets/Invoice.svg?react';
import QuoteCanceled from '@travauxlib/shared/src/components/DesignSystem/assets/QuoteCanceled.svg?react';
import Receipt from '@travauxlib/shared/src/components/DesignSystem/assets/Receipt.svg?react';
import { ChipPictoVerticalListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';
import { ProDevisView } from '@travauxlib/shared/src/types/api/pro/ProDevisView';
import { devisOrProposition } from '@travauxlib/shared/src/utils/wording';

import { useIsArchitecte } from 'api/profileSelectors';
import { FacturerFromDevis } from 'features/Deals/features/Factures/components/FacturerFromDevis';
import { useOpenFacturerAcompteModal } from 'features/Devis/components/FacturerDevisModal/Acompte';
import { useCreateFacturesFromModalites } from 'features/Devis/components/FacturerDevisModal/hooks/useCreateFacturesFromModalites';
import { SelectedPaymentType } from 'features/Devis/components/FacturerDevisModal/types';
import { useCreateAvoir } from 'features/Factures/api/useCreateAvoir';
import { useCreateFacture } from 'features/Factures/api/useCreateFacture';
import { useCreateSoldeToutCompte } from 'features/Factures/api/useCreateSoldeToutCompte';
import { getFactureUrl, getFactureSituationUrl } from 'utils/urls';

type Props = {
  dealUuid: string;
  devisSignes: ProDevisView[];
  handleClose: () => void;
};

export type FactureType = 'fromScratch' | 'fromDevis' | 'avoirFromScratch';
type PayFactureFormData = {
  devis: ProDevisView;
  factureTypeSelected: FactureType;
  selectedPaymentType: SelectedPaymentType;
};

export const CreateFactureModal: React.FC<Props> = ({ dealUuid, devisSignes, handleClose }) => {
  const navigate = useNavigate();
  const isArchitecte = useIsArchitecte();
  const createFacture = useCreateFacture();
  const createAvoir = useCreateAvoir();
  const { createSoldeToutCompte } = useCreateSoldeToutCompte();
  const openFacturerAcompteModal = useOpenFacturerAcompteModal();
  const createFacturesFromModalites = useCreateFacturesFromModalites();

  const options = [
    {
      centerIcon: <Receipt />,
      value: 'fromScratch',
      label: 'Vierge',
    },
    {
      centerIcon: <QuoteCanceled />,
      value: 'avoirFromScratch',
      label: 'Avoir',
    },
    {
      centerIcon: <Invoice />,
      value: 'fromDevis',
      label: `Liée à ${devisOrProposition({
        isArchitecte,
        prefix: 'un',
      })} signé${isArchitecte ? 'e' : ''}`,
      disabled: devisSignes.length === 0,
    },
  ];

  return (
    <Form<PayFactureFormData>
      onSubmit={async ({ devis, selectedPaymentType, factureTypeSelected }) => {
        if (factureTypeSelected === 'fromScratch') {
          createFacture({ dealUuid });
          return;
        }

        if (factureTypeSelected === 'avoirFromScratch') {
          createAvoir({ dealUuid });
          return;
        }

        if (selectedPaymentType === 'acompte') {
          openFacturerAcompteModal(devis);
        } else if (selectedPaymentType === 'total') {
          const factureSolde = await createSoldeToutCompte({
            dealUuid: devis.dealUuid,
            proDevisToken: devis.token,
          });
          handleClose();
          navigate(getFactureUrl(factureSolde));
        } else if (selectedPaymentType === 'situation') {
          navigate(getFactureSituationUrl(devis));
        } else {
          createFacturesFromModalites(devis);
          handleClose();
        }
      }}
      initialValues={{ factureTypeSelected: 'fromScratch' }}
    >
      {({ submitting, handleSubmit, values: { devis, factureTypeSelected }, invalid }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          cancelAction={{
            label: 'Annuler',
            onClick: handleClose,
          }}
          validateAction={{
            label: 'Valider',
            type: 'submit',
            disabled: submitting || invalid,
          }}
        >
          <ChipPictoVerticalListField
            id="facture-type-selected"
            name="factureTypeSelected"
            options={options}
            size="sm"
            className="flex gap-md"
          />
          {factureTypeSelected === 'fromDevis' && (
            <FacturerFromDevis devisSelected={devis} devisSignes={devisSignes} />
          )}
        </ModalContent>
      )}
    </Form>
  );
};
