import React from 'react';

import { Form } from 'react-final-form';
import { useNavigate } from 'react-router';

import { ChipPictoVerticalListField } from '@travauxlib/shared/src/components/DesignSystem/components/Chips/Field';
import { ModalContent } from '@travauxlib/shared/src/components/DesignSystem/components/Modal';

import { useCreateSoldeToutCompte } from 'features/Factures/api/useCreateSoldeToutCompte';
import { HybridDevis } from 'types';
import { getFactureSituationUrl, getFactureUrl } from 'utils/urls';

import { useOpenFacturerAcompteModal } from './Acompte';
import { useCreateFacturesFromModalites } from './hooks/useCreateFacturesFromModalites';
import { useGetFacturationMethodOptions } from './hooks/useGetFacturationMethodOptions';
import { SelectedPaymentType } from './types';

type Props = {
  devis: HybridDevis;
  onClose: () => void;
};

type SelectFactureOptionFormData = {
  selectedPaymentType: SelectedPaymentType;
};

export const Choice: React.FC<Props> = ({ onClose, devis }) => {
  const navigate = useNavigate();
  const { createSoldeToutCompte, isLoading } = useCreateSoldeToutCompte();
  const openFacturerAcompteModal = useOpenFacturerAcompteModal();
  const createFacturesFromModalites = useCreateFacturesFromModalites();

  const options = useGetFacturationMethodOptions(devis);

  return (
    <Form<SelectFactureOptionFormData>
      onSubmit={async ({ selectedPaymentType }) => {
        if (selectedPaymentType === 'total') {
          const factureSolde = await createSoldeToutCompte({
            dealUuid: devis.dealUuid,
            proDevisToken: devis.token,
          });
          onClose();
          navigate(getFactureUrl(factureSolde));
        } else if (selectedPaymentType === 'acompte') {
          openFacturerAcompteModal(devis);
        } else if (selectedPaymentType === 'situation') {
          navigate(getFactureSituationUrl(devis));
        } else {
          createFacturesFromModalites(devis);
          onClose();
        }
      }}
      initialValues={{ selectedPaymentType: options.find(value => !value.disabled)?.value }}
    >
      {({ handleSubmit, submitting }) => (
        <ModalContent
          handleSubmit={handleSubmit}
          validateAction={{
            label: 'Valider',
            type: 'submit',
            disabled: submitting || isLoading,
          }}
          cancelAction={{
            label: 'Annuler',
            onClick: onClose,
          }}
        >
          <p className="text-b1 mb-md text-neutral-600">
            Veuillez sélectionner une méthode de facturation
          </p>
          <ChipPictoVerticalListField
            id="selected-payment-type"
            name="selectedPaymentType"
            options={options}
            size="sm"
            className="flex gap-md"
          />
        </ModalContent>
      )}
    </Form>
  );
};
