import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import { ProRegistrationUserProfileView } from '@travauxlib/shared/src/types/api/pro/ProRegistrationUserProfileView';
import { request } from '@travauxlib/shared/src/utils/request';
import { stringifyParams } from '@travauxlib/shared/src/utils/urls';

import { AUTH_KEY } from 'api/useProfile';
import { dashboardUrl, registrationUrl } from 'utils/urls';

export const useVerifyEmail = (token: string): (() => Promise<ProRegistrationUserProfileView>) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: verifyEmail } = useMutation({
    mutationFn: () =>
      request(`${APP_CONFIG.apiURL}/pro-registrations/${token}/verify-email`, {
        method: 'PATCH',
      }),
    onSuccess: (registration: ProRegistrationUserProfileView) => {
      if (registration.proCompanyUuid) {
        queryClient.invalidateQueries({ queryKey: [AUTH_KEY] });
        toast.success('Votre adresse email a bien été vérifiée!');
        navigate(dashboardUrl);
      } else {
        navigate(
          `${registrationUrl}/siret?${stringifyParams({
            token,
          })}`,
        );
      }
    },
    onError: () => {
      toast.error("Le token n'est pas valide, veuillez vous ré-inscrire ou contacter le support");
      setTimeout(() => navigate(registrationUrl), 3000);
    },
  });

  return verifyEmail;
};
