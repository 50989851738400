import React, { FC, useState } from 'react';

import { Form } from 'react-final-form';
import { v4 as uuidV4 } from 'uuid';

import Cross from '@travauxlib/shared/src/components/DesignSystem/assets/CrossSymbolFilled.svg?react';
import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { ConversationParticipantView } from '@travauxlib/shared/src/types/api/pro/ConversationParticipantView';
import { required, requiredValidEmail } from '@travauxlib/shared/src/utils/form';

import { useCreateOrUpdateParticipant } from 'features/Deals/api/useParticipants';

type FormValues = {
  email: string;
  name: string;
};

export const ParticipantForm: FC<{
  participant?: ConversationParticipantView;
  conversationUuid: string;
  isNew?: boolean;
  onSubmit?: () => void;
  handleClose: () => void;
}> = ({ conversationUuid, participant, isNew, onSubmit, handleClose }) => {
  const [id] = useState(uuidV4());
  const { createOrUpdate } = useCreateOrUpdateParticipant();
  const uuid = participant?.uuid;
  return (
    <div className="!p-sm text-sm !rounded !h-full !bg-primarylighter">
      <div className="absolute p-xs top-0 right-0 z-10">
        <Cross className="h-lg w-lg cursor-pointer" onClick={handleClose} />
      </div>
      <h5 className="!font-bold mb-md">
        {isNew ? 'Ajouter un nouveau membre' : 'Modifier les infos du membre'}
      </h5>
      <Form<FormValues>
        initialValues={participant}
        onSubmit={async (values, form) => {
          await createOrUpdate({ ...values, conversationUuid });
          onSubmit?.();
          window.setTimeout(() => form.restart());
          handleClose();
        }}
      >
        {({ handleSubmit, dirty, valid }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-md">
              <InputField validate={required} id={`name-${id}`} label="Nom complet" name="name" />
            </div>
            <div className="mb-xl">
              <InputField
                id={`email-${id}`}
                validate={requiredValidEmail}
                label="Email"
                name="email"
              />
            </div>
            <Button disabled={!dirty || !valid} type="submit">
              {uuid ? 'Modifier' : '+ Ajouter un nouveau membre'}
            </Button>
          </form>
        )}
      </Form>
    </div>
  );
};
