import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { toast } from '@travauxlib/shared/src/components/Notifications';
import {
  MessageView,
  MessageAttachmentView,
} from '@travauxlib/shared/src/types/api/pro/MessageView';
import { request } from '@travauxlib/shared/src/utils/request';

import { CONVERSATION_PARTICIPANTS_KEY } from './useParticipants';

type SendMessagePayload = {
  conversationUuid: string;
  content: string;
  attachments?: Omit<MessageAttachmentView, 'createdAt'>[];
  uuid: string;
};

const handleSendMessage = async ({
  conversationUuid,
  content,
  attachments = [],
  uuid,
}: SendMessagePayload): Promise<{ conversationUuid: string; message: MessageView }> => {
  const requestURL = `${APP_CONFIG.proApiURL}/conversations/${conversationUuid}/messages`;

  const message = await request(requestURL, {
    method: 'POST',
    body: { content, attachments, uuid },
  });
  return {
    conversationUuid,
    message,
  };
};

const handleUploadAttachment = ({
  conversationUuid,
  attachment,
}: {
  conversationUuid: string;
  attachment: File;
}): Promise<void> => {
  const requestURL = `${APP_CONFIG.proApiURL}/conversations/${conversationUuid}/attachments`;
  const formData = new FormData();
  formData.append('attachment', attachment);
  return request(requestURL, {
    method: 'POST',
    body: formData,
  });
};

const handleDeleteAttachment = (filename: string): Promise<void> => {
  const requestURL = `${APP_CONFIG.proApiURL}/conversations/attachments/${filename}`;
  return request(requestURL, { method: 'DELETE' });
};

export const useConversation = (
  conversationUuid: string,
): {
  messages: MessageView[];
  sendMessage: typeof handleSendMessage;
  uploadAttachment: typeof handleUploadAttachment;
  deleteAttachment: typeof handleDeleteAttachment;
} => {
  const { data } = useQuery<{ data: MessageView[] }>({
    queryKey: ['conversation', conversationUuid],
    queryFn: async () => {
      try {
        const requestURL = `${APP_CONFIG.proApiURL}/conversations/${conversationUuid}/messages`;
        return request(requestURL);
      } catch (err) {
        toast.error("Les messages de la conversation n'ont pas pu être récupérés");
        throw err;
      }
    },
  });

  const messages = data?.data || [];
  const queryClient = useQueryClient();
  const { mutateAsync: sendMessage } = useMutation({
    mutationFn: handleSendMessage,
    onSuccess: ({
      conversationUuid,
      message,
    }: {
      conversationUuid: string;
      message: MessageView;
    }) => {
      queryClient.setQueryData(['conversation', conversationUuid], {
        data: [...messages, message],
      });
      queryClient.invalidateQueries({ queryKey: [CONVERSATION_PARTICIPANTS_KEY] });
    },
    onError: () => {
      toast.error("Le message n'a pas pu être envoyé");
    },
  });

  const { mutateAsync: uploadAttachment } = useMutation({ mutationFn: handleUploadAttachment });

  const { mutateAsync: deleteAttachment } = useMutation({ mutationFn: handleDeleteAttachment });

  return {
    messages,
    sendMessage,
    uploadAttachment,
    deleteAttachment,
  };
};
