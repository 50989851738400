import React, { FC } from 'react';

import { DealNoteView } from '@travauxlib/shared/src/types/api/pro/DealView';

import { MessageAttachment } from './MessageAttachment';

import { useConversation } from '../../api/useConversation';
import { MessageFrontView } from '../MessagesList/types';
import { notesToMessages } from '../MessagesList/utils/notesToMessages';

type MessageAttachmentListProps = {
  notes: DealNoteView[];
  conversationUuid: string;
};

export const MessageAttachmentList: FC<MessageAttachmentListProps> = ({
  conversationUuid,
  notes,
}) => {
  const { messages } = useConversation(conversationUuid);
  const messageWithNotes: MessageFrontView[] = notesToMessages(notes).concat(messages);

  const attachments = messageWithNotes.flatMap(message => {
    if (message.attachments.length > 0) {
      return message.attachments.map(({ filename, url, uuid }) => (
        <div className="text-sm !mx-md !py-xmd !border-b !mb-0" key={uuid}>
          <MessageAttachment filename={filename} url={url} />
        </div>
      ));
    }
    return null;
  });
  if (!attachments.find(attachment => attachment != null)) {
    return (
      <div className="!pb-sm !flex !items-center !justify-center text-sm !text-gray-600 flex-grow">
        Vous n'avez pas encore de documents partagés
      </div>
    );
  }
  return <div className="!pb-sm">{attachments}</div>;
};
